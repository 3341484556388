<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title
					a.btn.btn-default(@click="subtractMonth()")
						i.la.la-angle-left
					span.month-title.ml-3.mr-3 {{ mDate.format('MMMM YYYY') }} Overview
					a.btn.btn-default(@click="addMonth()")
						i.la.la-angle-right

				// a.btn.btn-circle.btn-primary(v-b-tooltip.hover.left, title="Sync Month", @click="syncMonth()")
					i.la.la-sync
				router-link.btn.btn-circle.btn-primary(:to="{name:'finance-list'}")
					i.la.la-list
			.widget-body
				.row
					.col-sm-12
						.d-flex.justify-content-between
							h1(v-if="publisherData.estimated_usd || advertiserData.estimated_usd", style="color: #666") {{ profit - publisherData.estimated_usd | toCurrency }} ~ {{ profit + advertiserData.estimated_usd | toCurrency }} GP
							h1(v-if="!publisherData.estimated_usd && !advertiserData.estimated_usd") {{ profit | toCurrency }} GP
							h3.growth(:style="{'color': growth >= 0 ? '#009b00' : '#9b0000'}")
								| (
								i.la(:class="{'la-arrow-up': growth >= 0, 'la-arrow-down': growth < 0}")
								| {{ Math.abs(growth) }}% )
				.row
					.col-sm-6
						finance-radial-chart(:series="advertiserSeries", :total="advertiserData.total_usd", :title="'Demand'")
						.justify-content-md-center(style="width: 400px; margin: auto")
							b-list-group
								//b-list-group-item.d-flex.justify-content-between.align-items-center(:to="{name:'finance-advertisers', query:{ year, month, status: [0,5]}}")
									| Estimated
									.price {{ advertiserData.estimated_usd | toCurrency }}
								b-list-group-item.d-flex.justify-content-between.align-items-center(:variant="advertiserData.confirmed === advertiserData.total ? 'success' : ''", :to="{name:'finance-advertisers', query:{ year, month }}")
									span Confirmed
										items-ready.ml-1(:ready="advertiserData.confirmed", :total="advertiserData.total")
									.price
										span {{ advertiserData.confirmed_usd | toCurrency }}
										small.ml-1 ({{ Math.round(100 * advertiserData.confirmed_usd / (advertiserData.confirmed_usd + advertiserData.estimated_usd)) }}%)
								b-list-group-item.d-flex.justify-content-between.align-items-center(:variant="advertiserData.sent === advertiserData.total ? 'success' : ''", :to="{name:'finance-advertisers', query:{ year, month }}")
									span Sent
										items-ready.ml-1(:ready="advertiserData.sent", :total="advertiserData.total")
									.price {{ advertiserData.sent_usd | toCurrency }}
								b-list-group-item.d-flex.justify-content-between.align-items-center(:variant="advertiserData.paid === advertiserData.total ? 'success' : ''", :to="{name:'finance-advertisers', query:{ year, month }}")
									span Paid
										items-ready.ml-1(:ready="advertiserData.paid", :total="advertiserData.total")
									.price {{ advertiserData.paid_usd | toCurrency }}

					.col-sm-6
						finance-radial-chart(:series="publisherSeries", :total="publisherData.total_usd", :title="'Supply'")
						.justify-content-md-center(style="width: 400px; margin: auto")
							b-list-group
								//b-list-group-item.d-flex.justify-content-between.align-items-center(:to="{name:'finance-publishers', query:{ year, month, status: [0,4]}}")
									| Estimated
									.price {{ publisherData.estimated_usd | toCurrency }}
								b-list-group-item.d-flex.justify-content-between.align-items-center(:variant="publisherData.confirmed === publisherData.total ? 'success' : ''", :to="{name:'finance-publishers', query:{ year, month }}")
									span Confirmed
										items-ready.ml-1(:ready="publisherData.confirmed", :total="publisherData.total")
									.price
										span {{ publisherData.confirmed_usd | toCurrency }}
										small.ml-1 ({{ Math.round(100 * publisherData.confirmed_usd / (publisherData.confirmed_usd + publisherData.estimated_usd)) }}%)
								b-list-group-item.d-flex.justify-content-between.align-items-center(:variant="publisherData.paid === publisherData.total ? 'success' : ''", :to="{name:'finance-publishers', query:{ year, month }}")
									span Paid
										items-ready.ml-1(:ready="publisherData.paid", :total="publisherData.total")
									.price {{ publisherData.paid_usd | toCurrency }}

</template>
<style lang="scss">
.widget-header {
  h1.title {
    i.la {
      font-size: 20px;
    }
  }
}
.price {
  font-weight: bold;
}
</style>

<script>
import FinanceRadialChart from './FinanceRadialChart';
import ItemsReady from './ItemsReady';
import moment from 'moment';

export default {
  name: 'FinanceMonthSummary',
  components: {
    FinanceRadialChart,
    ItemsReady,
  },
  async beforeRouteEnter(to, from, next) {
    let selectedMonth = to.params.month;
    let mDate = selectedMonth ? moment(selectedMonth, 'YYYY-MM') : moment().subtract(2, 'months');

    next(async (vm) => {
      vm.mDate = mDate;
      vm.month = mDate.format('M');
      vm.year = mDate.format('YYYY');
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      mDate: null,
      month: null,
      year: null,
      advertiserData: {
        confirmed: 0,
        sent: 0,
        paid: 0,
        total: 0,
      },
      publisherData: {
        confirmed: 0,
        paid: 0,
        total: 0,
      },
      profit: 0,
      prior_profit: 0,
      growth: 0,
    };
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    advertiserSeries() {
      return [
        { label: 'Confirmed $', value: this.advertiserData.confirmed_usd, color: '#0084ff' },
        { label: 'Sent $', value: this.advertiserData.sent_usd, color: '#39539E' },
        { label: 'Paid $', value: this.advertiserData.paid_usd, color: '#0077B5' },
      ];
    },
    publisherSeries() {
      return [
        { label: 'Confirmed $', value: this.publisherData.confirmed_usd, color: '#0084ff' },
        { label: 'Paid $', value: this.publisherData.paid_usd, color: '#0077B5' },
      ];
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    subtractMonth() {
      this.mDate.subtract(1, 'month');
      this.year = this.mDate.format('YYYY');
      this.month = this.mDate.format('M');
      this.loadData();
    },

    addMonth() {
      this.mDate.add(1, 'month');
      this.year = this.mDate.format('YYYY');
      this.month = this.mDate.format('M');
      this.loadData();
    },

    async loadData() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        month: this.month,
        year: this.year,
      };
      try {
        let resp = await this.$ovReq.get('finance/summary/get', { params });
        this.advertiserData = resp.advertiser;
        this.publisherData = resp.publisher;
        this.profit = resp.profit;
        this.prior_profit = resp.prior_profit;
        this.growth = Math.round(
          resp.prior_profit > 0 ? 100 * ((resp.profit - resp.prior_profit) / resp.prior_profit) : 0,
        );
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    async syncMonth() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        year: this.year,
        month: this.month,
      };
      try {
        await this.$ovReq.post('finance/advertiser/syncMonth', params);
        await this.$ovReq.post('finance/publisher/syncMonth', params);
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
