<template lang="pug">
	span
		span(v-if="!isDone") ({{ ready }} / {{ total }})
		i.la.la-check(v-else)
</template>
<style lang="scss">
i.la.la-check {
  color: #0e6b0e;
}
</style>
<script>
export default {
  name: 'ItemsReady',
  props: ['ready', 'total'],
  computed: {
    isDone() {
      return this.total > 0 && this.ready === this.total;
    },
  },
};
</script>
