<template lang="pug">
	#chart
		apexchart(type="radialBar", height="300", :options="chartOptions", :series="chartSeries")

</template>
<style lang="scss"></style>
<script>
function getPercentage(amount, total) {
  if (!total) return 0;
  return Math.round((100 * amount) / total);
}

export default {
  name: 'FinanceRadialChart',
  props: ['total', 'series', 'title'],
  methods: {
    isDone() {
      return (
        this.total > 0 && this.series.reduce((done, s) => done && s.value === this.total, true)
      );
    },
  },
  computed: {
    chartOptions() {
      let vm = this;
      let isDone = vm.isDone();
      return {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: true,
                label: vm.title,
                formatter: function (w) {
                  let sum = w.globals.series.reduce((acc, s) => acc + s, 0);
                  return `${Math.round(sum / w.globals.series.length)}%`;
                },
              },
            },
          },
        },
        legend: {
          show: !isDone,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 0,
          offsetY: 20,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return `${seriesName}:  ${vm.series[opts.seriesIndex].value} / ${vm.total}`;
          },
          itemMargin: {
            vertical: 3,
          },
        },
        colors: !isDone ? this.series.map((s) => s.color) : ['#009b00'],
        labels: this.series.map((s) => s.label),
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      };
    },
    chartSeries() {
      if (this.isDone()) {
        return [100];
      }
      return this.series.map((s) => getPercentage(s.value, this.total));
    },
  },
};
</script>
